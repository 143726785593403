var msg: Translations = {
    DELETEALLPRODUCTSERROR: 'Errore di eliminazione dei prodotti dal carrello',
    ERR_CARRIERMISSING: 'Devi selezionare un tipo di spedizione',
    ERR_CARRIER_COST_MISSING: 'Nessuna tariffa applicabile per nazione, corriere e peso dell\'ordine.',
    ERR_CLIENTMANDATORYFIELDS: 'I campi contrassegnati sono obbligatori',
    ERR_DATE_FROM: "La data deve essere succesiva a :from",
    ERR_DATE_FROM_TO: "La data deve essere tra :from e :to",
    ERR_DATE_TO: "La data deve essere precedente a :to",
    ERR_FILLEMAILFIELD: 'Compila il campo email',
    ERR_INVALIDMAIL: 'Email non valida',
    ERR_LIGHTBOX_LOADING: 'Dettaglio immagini in caricamento, riprova tra qualche secondo',
    ERR_LINK_NOT_COPIED: "Il link non può essere copiato",
    ERR_LOGININVALID: 'Email e/o password non validi',
    ERR_LOGINREQUIRED: 'Devi effettuare l\'accesso',
    ERR_MISSINGFIELDS: 'Completa correttamente tutti i campi obbligatori',
    ERR_NOSIZESELECTED: 'Devi prima selezionare una taglia',
    ERR_PEC_OR_SDI: 'Compila almeno un campo tra PEC e SDI',
    ERR_PRIVACY: 'Devi accettare le condizioni',
    ERR_USEREXISTS: 'Utente già esistente.',
    ERR_VAT: 'Formato partita IVA non corretto',
    ERR_VAT_PREFIX: 'La partita IVA deve cominciare con il codice della nazione (per esempio IT)',
    LABEL_ALREADYREGISTEREDUSERES: 'Utenti già registrati',
    LABEL_ARTICLES: 'articoli',
    LABEL_CANCEL: 'Cancella',
    LABEL_CONFIRM: 'Conferma',
    LABEL_INVITES_ALREADY_SENT: 'Utenti già invitati',
    LABEL_NOTAPPLICABLE: 'Non applicabili',
    LABEL_NOTSENTINVITES: 'Inviti non inviati',
    LABEL_SELECTALL: 'Seleziona tutti',
    LABEL_SENTINVITES: 'Inviti inviati',
    LABEL_SUBMIT: 'Invia',
    LABEL_WARNING: 'Attenzione',
    MSG_ADDEDTOCART: 'Prodotti aggiunti al carrello',
    MSG_ADDEDTOWISHLIST: 'Prodotto aggiunto alla lista dei desideri',
    MSG_APPLIEDVATCHANGED: 'Dopo questo aggiornamento dei dati di fatturazione, l\'IVA applicata al tuo ordine è cambiata.',
    MSG_COUPON_ERROR: 'Codice coupon inserito non valido o inserito precedentemente.',
    MSG_COUPON_VALID: 'Codice coupon inserito validato con successo.',
    MSG_DEFERRED_DISPATCH_COMPUTATION: 'Sarà abilitata la sola prenotazione dell\'ordine e le spese di spedizione verranno comunicate via email',
    MSG_DELETEALLPRODUCTS: 'Sei sicuro di voler eliminare tutti i prodotti dal carrello?',
    MSG_EXTRAUELIMIT: 'L\'ammontare minimo del tuo ordine non può essere inferiore a 2000.00 &euro;',
    MSG_GENERIC_ERROR: 'Spiacente, si è verificato un errore.',
    MSG_INSUFFICIENT_STOCK: 'Al momento la disponibilità del prodotto non è sufficiente.',
    MSG_INVALID_CAPTCHA: 'Codice captcha non valido',
    MSG_LINK_COPIED: "Link copiato",
    MSG_MAILSENT: 'E-Mail inviate',
    MSG_MESSAGE_PRODUCTSHARE: 'Messaggio',
    MSG_MESSAGE_SENT: 'Messaggio inviato!',
    MSG_MIXED_CART: 'I prodotti nel carrello non possono essere acquistati insieme al prodotto selezionato perchè si trovano in magazzini diversi.',
    MSG_MIXED_INTANGIBLE: 'I prodotti nel carrello non possono essere acquistati insieme al prodotto selezionato.',
    MSG_MUST_ACCEPT_TERMS: 'Devi accettare i termini d\'uso',
    MSG_NEWSLETTER_COMPLETED: 'Iscrizione effettuata con successo',
    MSG_PASSWORD_NOT_COMPLEX: 'La nuova password non è abbastanza complessa, deve includere almeno 1 carattere minuscolo, 1 maiuscolo e 1 numero ed essere lunga almeno 8 caratteri.',
    MSG_PASSWORD_NOT_EQUAL: 'Le due password non corrispondono',
    MSG_PASSWORD_OK: 'La password è stata modificata',
    MSG_PASSWORD_RESET: 'La password è stata reimpostata con successo',
    MSG_PRIVACY_POLICY: 'Devi accettare la privacy policy',
    MSG_PROFILECONSENT_SAVED: 'Le tue preferenze di contatto sono state aggiornate',
    MSG_RECOVEREMAILSENT: 'Controlla la tua email e clicca sul link per confermare la modifica.',
    MSG_RECOVEREMAILSENT_ERROR: 'Attenzione: Non sei registrato con questo indirizzo e-mail o stai già reimpostando una nuova password.',
    MSG_REMOVEDFROMWISHLIST: 'Prodotto rimosso dalla lista dei desideri',
    MSG_RETURN_REQUEST: 'Richiesta di reso completata con successo',
    MSG_RETURN_REQUEST_ERROR: 'La richiesta di reso non può essere completata, contatta l\'amministratore',
    MSG_RETURN_REQUEST_SUCCESS: 'Richiesta di reso creata con success',
    MSG_RULE_REJECT: 'Il prodotto non può essere aggiunto al carrello per via della regola :rule',
    MSG_SAVE_SUCCESS: 'Salvataggio avvenuto con successo',
    MSG_SELECT_PROVINCE: 'Seleziona la tua provincia',
    MSG_SENDER_PRODUCTSHARE: 'Il tuo nome',
    MSG_SUBJECT_PRODUCTSHARE: ' ha condiviso qualcosa con te',
    MSG_SUBSCRIBED: 'Iscritto con successo',
    MSG_SUBSCRIPTION_FAILURE: 'Make sure you do not have any other active subscription.',
    MSG_SUBSCRIPTION_NO_RENEWABLE: 'Sono passati troppi giorni quindi l’abbonamento non è più rinnovabile',
    MSG_TOKEN_SENT: 'Abbiamo appena inviato un codice alla tua email. Verifica nella tua posta se hai ricevuto il messaggio che ti è stato inviato e immetti qui il codice',
    MSG_USER_ALREADY_REGISTERED: 'Utente già registrato',
    MSG_USER_NOT_FOUND: 'Utente non registrato',
    MSG_WAIT: 'Attendere...',
    MSG_WRONG_PASSWORD: 'La password inserita non è corretta'
}